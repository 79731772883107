
import { defineComponent, SetupContext, onMounted, reactive, toRefs, watch, computed, getCurrentInstance } from 'vue';
import ActionSheet from 'vant/lib/action-sheet';
import dayjs from 'dayjs';
import { useWebToApp } from '~/hooks/useWebToApp';
import { sendLog } from '~/common/radar';
import { SafetyLocalStorage } from '@/common/storage';

const textMap: Record<string, string> = {
    openPwa: 'open',
    installPwa: 'install pwa',
};

export default defineComponent({
    components: {
        ActionSheet,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        componentType: {
            // openPwa:使用pwa 打开弹窗；installPwa安装pwa 弹窗
            type: String,
            default: 'installPwa',
        },
        isFirstOpen: {
            type: Boolean, // 是否首次打开pwa
            default: false,
        },
    },
    emits: ['changeShow'],
    setup(props, ctx: SetupContext) {
        const { proxy }: any = getCurrentInstance();
        const { installPwa } = useWebToApp();
        const { emit } = ctx;
        const handleClose = () => {
            if (props.componentType === 'openPwa') {
                sendLog({
                    type: 'click',
                    name: 'OPEN_PWA_DESKTOP',
                    value: {
                        click_area: 'close',
                        is_first_skip: props.isFirstOpen,
                    },
                });
                const pwaDataStr =
                    SafetyLocalStorage.getItem('pwaData') ||
                    JSON.stringify({
                        install: '',
                        openPwa: '',
                    });
                const pwaData = JSON.parse(pwaDataStr);
                pwaData.openPwa = dayjs().format('YYYY-MM-DD');
                SafetyLocalStorage.setItem('pwaData', JSON.stringify(pwaData));
            }
            emit('changeShow', false);
        };

        const title = computed(() => {
            if (props.componentType === 'openPwa') {
                return proxy.$root.$t('add_to_desktop_success_title');
            }
            return '';
        });

        const desc = computed(() => {
            if (props.componentType === 'openPwa') {
                return proxy.$root.$t('add_to_desktop_success_description');
            }
            return '';
        });

        const newShow = computed(() => props.show);

        // 使用pwa 打开
        const openWithPwa = () => {
            if (props.componentType === 'openPwa') {
                sendLog({
                    type: 'click',
                    name: 'OPEN_PWA_DESKTOP',
                    value: {
                        click_area: 'open',
                        is_first_skip: props.isFirstOpen,
                    },
                });
                emit('changeShow', false);
                // 使用pwa打开
                const link = `${window.location.origin}/aichat?&pwa_source=desktop`;
                window.open(link, '_blank');
            } else {
                // 点击 => 安装提示
                installPwa().finally(() => {
                    emit('changeShow', false);
                });
            }
        };

        watch(
            () => props.show,
            newVal => {
                if (newVal && props.componentType === 'openPwa') {
                    sendLog({
                        type: 'show',
                        name: 'OPEN_PWA_DESKTOP',
                        value: {
                            is_first_skip: props.isFirstOpen,
                        },
                    });
                }
            }
        );

        return {
            handleClose,
            openWithPwa,
            newShow,
            textMap,
            title,
            desc,
        };
    },
});
